<template>
	<div>
		<h3 v-if="requestConsultant">
			Select the Sale and enter the {{ translate('consignerStudy') }} Number for your submission to
			{{ requestConsultant.organizationName.replace(/\.$/, '') }}.
		</h3>
		<form class="sale-hip-form" @submit.prevent="validate">
			<div class="input-group">
				<label>Sale</label>
				<v-select
					:options="sales"
					label="name"
					:searchable="false"
					:clearable="false"
					:value="sale && sales.find(x => x.id === sale.id)"
					@input="sale = $event"
				/>
			</div>
			<div class="input-group">
				<label>{{ translate('consignerStudy') }} Number</label>
				<div class="hip-search">
					<input ref="searchInput" v-model.trim="searchText" class="input search" />
					<ast-button type="primary" :class="{ disabled: !sale || !sale.id || !searchText || isValidating }">
						<svg-icon icon="magnify" style="margin-right: 0; opacity: 1;" />
					</ast-button>
				</div>
			</div>
		</form>
		<transition name="expand">
			<!-- Errors that should not show sale entry info -->
			<p v-if="saleEntryStatus === 'saleEntryNotFound'" class="not-found">
				No entries found. This {{ translate('consignerStudy') }} number does not exist, or it has already been
				submitted.
			</p>
			<p v-else-if="saleEntryStatus === 'saleNotFoundOrClosed'" class="not-found">
				<!-- Should hopefully not ever be shown -->
				This sale is not open for submission at this time.
			</p>
			<!-- Sale Entry info -->
			<div v-else-if="saleEntryStatus" class="sale-entry">
				<h4>
					{{ translate('consignerStudy') }} {{ saleEntry.patientId }}
					<svg-icon v-if="canSubmit" icon="check-circle" class="is-success" style="float: right; font-size: 1.25em" />
				</h4>
				<ul>
					<li v-if="saleEntry.motherName">Dam: {{ saleEntry.motherName }}</li>
					<li v-if="saleEntry.fatherName">Sire: {{ saleEntry.fatherName }}</li>
					<li v-if="saleEntry.consignerName" class="consigner-name"> Consigner: {{ saleEntry.consignerName }} </li>
				</ul>
				<!-- Partial submission warning -->
				<div v-if="saleEntryStatus === 'saleEntryAlreadyHasImages' && canSubmit" class="status is-warning">
					<svg-icon icon="exclamation-triangle" />
					<p>
						Some files have already been submitted for this entry. However, you may proceed to add additional
						{{ additionalFiles }}.
					</p>
				</div>
				<!-- Errors with sale entry that prevent submission -->
				<div v-if="!canSubmit" class="status is-danger">
					<svg-icon icon="exclamation-triangle" />
					<!-- Already submitted -->
					<p v-if="saleEntryStatus === 'saleEntryAlreadyHasImages'">
						This entry has already been submitted completely. Duplicate submissions are not allowed.
					</p>
					<!-- A study is too old or has been used for another entry already -->
					<p v-if="['studyTooOld', 'studyAlreadySubmittedForSale'].includes(saleEntryStatus)">
						{{ saleEntry.errors.join(' ') }}
					</p>
					<!-- Session/entry is not available for submission -->
					<p v-if="saleEntryStatus === 'saleEntryNotOpenForSubmission'">
						Images may not be submitted at this time.
						<span v-if="saleEntry.submissionStartDate && saleEntry.submissionEndDate">
							The submission period for this entry is from
							{{ saleEntry.submissionStartDate | localizeDate }}
							to
							{{ saleEntry.submissionEndDate | localizeDate }}.
						</span>
					</p>
				</div>
			</div>
		</transition>
		<p v-if="requestConsultant" class="support">
			If any information is incorrect, please contact {{ requestConsultant.name }} at
			<a v-if="requestConsultant.email" target="hidden-iframe" :href="`mailto:${requestConsultant.email}`">
				{{ requestConsultant.email }}
			</a>
			<span v-if="requestConsultant.email && requestConsultant.phone">or</span>
			<a v-if="requestConsultant.phone" :href="`tel:${requestConsultant.phone}`" target="hidden-iframe">
				{{ requestConsultant.phone }}
			</a>
		</p>
	</div>
</template>

<script>
import api from '@services/api'
import AstButton from '@components/Button'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { salesService } from '@services/salesService'
import workflow from '@services/workflow'
import { uploadData } from '@services/uploads'
import { mapActions } from 'vuex'

export default {
	name: 'TeleconsultationRequestSaleHip',
	components: {
		AstButton,
	},
	props: {
		ids: {
			type: Array,
			required: true,
		},
		consultantId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isValidating: false,
			sales: [],
			searchText: '',
			service: salesService,
			workflow: workflow,
		}
	},
	computed: {
		saleEntryStatus() {
			return this.service.saleEntryStatus
		},
		requestConsultant() {
			return this.workflow.consultant
		},
		sale: {
			get() {
				return salesService.sale
			},
			set(sale) {
				salesService.sale = sale
				if (this.saleEntry) this.saleEntry = null
			},
		},
		saleEntry: {
			get() {
				return salesService.saleEntry
			},
			set(saleEntry) {
				salesService.saleEntry = saleEntry
			},
		},
		canSubmit() {
			if (!this.saleEntry) return false
			if (this.saleEntryStatus === 'success') return true
			if (this.saleEntryStatus === 'saleEntryAlreadyHasImages') {
				if (this.saleEntry.attachments.length) return true
				if (this.saleEntry.images.length) return true
			}
			return false
		},
		additionalFiles() {
			const words = []
			if (this.saleEntry.attachments.length) words.push('attachments')
			if (this.saleEntry.images.length) words.push('images')
			return words.join(' or ')
		},
	},
	async beforeRouteEnter(to, from, next) {
		workflow.canGoNext = false
		workflow.isLoading = true
		try {
			if (from && from.name === 'request-upload-sale') {
				const warning = 'Going back will reset your current submission.  Are you sure?'
				if (!(await showConfirm(warning))) return next(false)
				await resetReport()
			}
			let salesReq = salesService.getSubmissionSales(to.query.consultantId)
			let studyReq = Promise.resolve([])
			if (to.query.studyId) {
				studyReq = api.viewer.getStudy({ ids: to.query.studyId }, false)
			}
			let response = await Promise.all([salesReq, studyReq])
			const sales = response[0]
			const { studies } = response[1]
			let searchText = ''
			const isStudyData = studies && studies.length
			if (isStudyData) {
				// auto-select sale and hip if patientId or accessionNumber is either
				// SALECODE-HIP or LABEL-HIP
				const study = studies[0]
				const { isMatchingSale, hip } = selectMatchingSale(sales, study)
				if (isMatchingSale) searchText = hip
			}
			next(vm => {
				vm.sales = sales
				vm.sale = salesService.sale || sales[0]
				vm.searchText = searchText
			})
		} finally {
			workflow.isLoading = false
		}

		function resetReport() {
			uploadData.stopUploads({ context: 'teleconsultation-request' })
			salesService.reset()
			workflow.reset()
			workflow.consultantId = to.query.consultantId
		}
		function selectMatchingSale(sales, study) {
			const { code, hip } = parseCodeHip(study)
			let sale = sales.find(sale => sale.code.toUpperCase() === code)
			if (!sale) {
				const isMatchingLabel = ({ label }) => label.toUpperCase() === code
				sale = sales.find(sale => sale.labels.some(isMatchingLabel))
			}
			if (sale) {
				salesService.sale = sale
				return { isMatchingSale: true, hip }
			}
			return { isMatchingSale: false }
		}
		function parseCodeHip(study) {
			// check accessionNumber and patientId for CODE-HIP
			const pattern = /(^\w*)-(\d{4}.*$)/
			let matches = pattern.exec(study.accessionNumber.trim())
			if (!matches) matches = pattern.exec(study.patientId)
			if (!matches) return {}
			matches = matches.map(match => match.toUpperCase())
			return { code: matches[1], hip: matches[2] }
		}
	},
	watch: {
		canSubmit: {
			handler(canSubmit) {
				workflow.canGoNext = canSubmit
			},
			immediate: true,
		},
		'sale.id'() {
			this.$refs.searchInput.select()
		},
		searchText() {
			this.saleEntry = { images: [], attachments: [] }
		},
	},
	mounted() {
		workflow.nextRoute = {
			name: 'request-upload-sale',
			query: this.$route.query,
		}
		// wait for potential searchText value from beforeRouteEnter
		this.clearAttachmentsAction()
		setTimeout(this.validate, 0) // nextTick was not enough
	},
	methods: {
		...mapActions(['getAttachmentsAction', 'clearAttachmentsAction']),
		async validate() {
			if (!this.sale || !this.sale.id || !this.searchText) return

			this.searchText = this.searchText.padStart(4, 0);

			this.isValidating = true
			try {
				await salesService.getTemplate({
					saleId: this.sale.id,
					hipNumber: this.searchText,
					studyIds: this.ids,
				})
				await this.getAttachmentsAction({
					consultantId: workflow.consultantId,
					saleId: this.sale.id,
					hipNumber: this.searchText,
					studyIds: this.ids,
				})
			} finally {
				this.isValidating = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
.sale-hip-form {
	width: auto;
	max-width: 400px;
}
.input-group {
	margin-top: 20px;
	label + * {
		margin-top: 4px;
	}
}
.hip-search {
	display: flex;
}
/* hide number input spin buttons */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}
input.search {
	width: 180px;
}
button {
	margin-left: 8px;
	font-size: 0.8em;
}
.sale-entry {
	background: var(--card-bg);
	border: 1.25px solid var(--card-border); // 1.25px due to weird rendering
	margin-top: 20px;
	max-width: 500px;
	overflow: hidden;
	padding-bottom: 1em;
	h4 {
		background: var(--card-header-default-bg);
		padding: 1em;
		overflow: hidden;
	}
	ul {
		list-style-type: none;
		padding: 0 1em;
		li:first-child {
			padding-top: 1em;
		}
		li {
			padding: 0.1em 0;
		}
	}
	.consigner-name {
		padding-top: 1em;
	}
	.status {
		display: flex;
		align-items: center;
		padding: 1em 1em 0 1em;
		font-weight: 400;
		.icon {
			margin-right: 1em;
		}
	}
}
p.not-found,
p.support {
	margin-top: 40px;
}
p.not-found {
	font-weight: 400;
}
p.support::after {
	content: '.';
	margin-left: -0.25ch; /* HACK: extra whitespace for some reason?? */
}
</style>
